import { render, staticRenderFns } from "./block.vue?vue&type=template&id=483bab6c&scoped=true&"
import script from "./block.vue?vue&type=script&lang=js&"
export * from "./block.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "483bab6c",
  null
  
)

export default component.exports